<template lang="pug">
	v-content(class="MyTours")
		v-container(grid-list-xl)
			h1
				| Mis tours

			v-divider

			v-layout(row wrap v-if="loading")
				v-flex(
					xs12
					sm6
					md4
					lg3
					v-for="i in [1,2,3,4]"
					:key="i"
				)
					tour(loading)

			v-layout(row wrap v-else)
				v-flex(
					xs12
					sm6
					md4
					lg3
					v-for="(tour, i) in tours"
					:key="i"
				)
					tour(:tour="tour" @delete="onDelete(tour)" @share="onShare(tour)" options)

				div(class="MyTours__no-tours text-xs-center mx-auto mt-5" v-if="tours.length === 0")
					v-icon(x-large) mdi-panorama
					h3 No hay tours disponibles
					p
						| ¿Qué tal si creas uno? Haz click&nbsp;
						a(target="_blank" href="https://virtualitour.es/guias/crear-recorrido-virtual/") aquí
						| &nbsp;para más información

		v-btn(
			color="pink"
			class="add-button"
			large
			absolute
			bottom
			right
			fixed
			fab,
			:to="{name: 'createTour'}"
		)
			v-icon(class="white--text") mdi-plus

		v-dialog(v-model="isShareDialogOpen" max-width="500")
			v-card
				v-card-text
					v-tabs(fixed-tabs)
						v-tab Compartir
						v-tab Integrar
						v-tab-item(class="pa-3")
							social-sharing(
								v-if="isShareDialogOpen"
								inline-template
								:url="url"
								:title="tourToShare.name"
								description="Chequea nuestro nuevo #virtualitour"
								hashtags="vr,virtualitour"
								twitter-user="virtualitour"
								@open="onShareNetwork"
							)
								div
									network(network="email")
										v-btn(icon flat)
											v-icon mdi-email

									network(network="facebook")
										v-btn(icon flat)
											v-icon mdi-facebook

									network(network="twitter")
										v-btn(icon flat)
											v-icon mdi-twitter

									network(network="whatsapp")
										v-btn(icon flat)
											v-icon mdi-whatsapp

							v-text-field(:value="url" label="Link" readonly)

						v-tab-item(class="pa-3")
							v-textarea(:value="iframe" readonly outline label="Código HTML")
</template>

<script>
	import Tour from '@/modules/tours/components/Tour';
	import {queries} from '@/modules/tours/services';
	import firebase from '@/modules/common/firebase';
	import {VIEWER} from '@/config'

	const analytics = firebase.analytics();

	export default {
		name: 'MyTours',
		data() {
			return {
				tours: [],
				loading: false,
				tourToShare: null,
				isShareDialogOpen: false
			}
		},

		components: {
			Tour
		},

		methods: {
			onShare(tour) {
				this.isShareDialogOpen = true;
				this.tourToShare = tour;
			},

			onShareNetwork(network) {
				analytics.logEvent('share', {
					method: network,
					content_id: this.tourToShare.id
				});
			},

			handleSuccessResponse(querySnapshot) {

				querySnapshot.forEach(tourRef => {
					const tour = tourRef.data();
					tour.id = tourRef.id;
					this.tours.push(tour);
				});

				this.loading = false;
			},

			handleErrorResponse(error) {
				this.loading = false;
				this.$notify({
					type: 'error',
					text: error.message
				});
			},

			handleSuccessDelete() {
				analytics.logEvent('delete_tour');
			},

			onDelete(tour) {
				this.toursDb.doc(tour.id).delete()
					.then(this.handleSuccessDelete)
					.catch(this.handleErrorResponse.bind(this));

				const tourIndex = this.tours.findIndex(item => item.id === tour.id);
				this.tours.splice(tourIndex, 1);
			}
		},

		mounted() {
			this.loading = true;
			queries
				.getMyTours({user: this.user})
				.then(this.handleSuccessResponse.bind(this))
				.catch(this.handleErrorResponse.bind(this));
		},

		computed: {
			user() {
				return firebase.auth().currentUser;
			},
			toursDb() {
				return this.$getDb().collection('tours');
			},
			url() {
				return this.tourToShare && `${VIEWER}/${this.tourToShare.id}`;
			},
			iframe() {
				return this.tourToShare && `<iframe width="560" height="315" src="${this.url}?autoLoad=false" frameborder="0" allowfullscreen></iframe>`;
			}
		}
	}
</script>

<style lang="less" scoped>
	.MyTours__no-tours {
		opacity: 0.5;
	}

	.add-button {
		margin-bottom: 140px;
		margin-right: 5px;
	}
</style>
